<template>
  <v-main>
    <v-container grid-list-xs>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <div class="text-h5">主新闻中心</div>
      <v-row>
        <v-col md="7" cols="12">
          <v-card>
            <v-card-text>
              <v-text-field
                label="媒体"
                v-model="press"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                label="标题"
                v-model="title"
                outlined
                dense
              ></v-text-field>
              <tiptap-vuetify v-model="content" :extensions="extensions" />
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="publish">发布</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="5" cols="12">
          <div v-if="mpcs.length > 0">
            <v-card>
              <div v-for="(mpc, index) in mpcs" :key="index">
                <v-card-title primary-title> {{ mpc.title }} </v-card-title>
                <v-card-subtitle>
                  {{ mpc.press }} <br />
                  {{ mpc.time }}
                </v-card-subtitle>
                <v-card-text>
                  <div v-html="mpc.content"></div>
                </v-card-text>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>
          <div v-else>还没有新闻内容</div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import db from "@/plugins/leancloud";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },
  name: "mpcPublish",
  metaInfo() {
    return {
      title: "MPC",
    };
  },
  props: ["id", "encodedKey"],
  data() {
    return {
      loading: false,
      title: "",
      press: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      content: ``,
      mpcs: [],
    };
  },
  computed: {},
  methods: {
    isAuthenticated() {
      var flag = false;
      var decodedString = window.atob(this.encodedKey);
      if (decodedString === this.id) {
        flag = true;
      }
      return flag;
    },
    async publish() {
      const answer = window.confirm(
        "确定提交？提交后代表们将可看到稿件并不可撤回"
      );
      if (answer) {
        const data = {
          press: this.press,
          title: this.title,
          content: this.content,
          time: new Date().toLocaleString(),
        };
        var conf = db.Object.createWithoutData("sessionConferences", this.id);
        await conf.add("mpcs", data).save();
        this.fetchMPC();
        console.log("published");
      } else {
        console.log("cancelled");
      }
    },
    fetchMPC() {
      this.loading = false;
      const query = new db.Query("sessionConferences");
      query
        .equalTo("objectId", this.id)
        .select(["mpcs"])
        .find()
        .then((resp) => {
          const obj = JSON.parse(JSON.stringify(resp[0]));
          console.log(obj.mpcs);
          this.mpcs = obj.mpcs;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    var valid = this.isAuthenticated();
    if (valid === true) {
      this.fetchMPC();
    } else {
      this.$router.push("/about");
    }
  },
};
</script>
<i18n>
{
  "en": {
    "account": "Account",
    "reset": "Reset",
    "resetConfirm":"Are you sure to clear data and restart?",
    "confirm": "Confirm",
    "about-session": "About Session",
    "about-munshare": "About Munshare",
    "immediateUse":"Chair MUN Online",
    "login":"Login",
    "munshare": "Munshare",
    "showPro": "Show Pro Logo",
    "hidePro": "Hide Pro Logo",
    "resetSession":"Clear the data and restore Session",
    "showConferenceName": "Display your conference name",
    "darkMode":"Dark Mode",
    "setTheme":"Set Theme",
    "themeColor":"Theme Color",
    "Thanks":"Thanks for your support"

  },
  "zh": {
    "account": "个人中心",
    "reset": "重置",
    "resetConfirm":"确定清除所有数据并重启App么？",
    "about-session": "关于模时Session",
    "confirm": "确定",
    "about-munshare": "关于模时",
    "showConferenceName": "会议名称展示",
    "showPro": "显示Pro Logo",
    "hidePro": "隐藏Pro Logo",
    "munshare": "模时",
    "immediateUse":"随开随用的模联议程",
    "resetSession":"重置Session全部数据，恢复原始状态。",
    "login":"登录",
    "setTheme":"主题设置",
    "darkMode":"夜间模式",
    "themeColor":"主题颜色",
    "Thanks":"感谢你使用Session Pro。如有任何使用问题，请联系模时。"
  }
}
</i18n>
